<template>
  <div>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :pulling-text="$t('下拉即可刷新...')"
      :loosing-text="$t('释放即可刷新...')"
      :loading-text="$t('加载中...')"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :style="{ 'min-height': screenHeight + 'px' }"
        :finished-text="$t('没有更多了')"
        @load="onLoad"
      >
        <div
          v-for="item in datas"
          :key="item.id"
          @click="topupDetail(item.id)"
          class="item-record van-hairline--bottom"
        >
          <van-cell style="font-size: 16px" class="record-top" is-link>
            <template #title>
              <div>
                <div>
                  {{ item.orderNo }}
                  <span
                    :style="
                      6 === item.status ? 'color: #00C468' : 'color: #f00'
                    "
                    v-show="
                      1 === item.status ||
                      5 === item.status ||
                      6 === item.status
                    "
                    >({{ convertPayStatus(item.status) }})</span
                  >
                </div>
              </div>
              <div>{{ item.userCode26 }}/{{ item.userCode }}</div>
              <span>{{
                (1 === item.orderType ? "-" : "+") + item.totalForeignFee
              }}</span
              ><br />
              <span style="color: #999; margin-top: 10px; font-size: 14px">{{
                formatTime(item.createdAt)
              }}</span>
            </template>
          </van-cell>
        </div>
      </van-list>

      <van-empty v-if="datas.length == 0" description="暂无记录" />
    </van-pull-refresh>
  </div>
</template>

<script>
import { Button, List, Toast, PullRefresh, Empty, Cell } from "vant";
import { formatShortTime } from "@/utils/utils";
import { topupList } from "@/service/api";

export default {
  components: {
    [List.name]: List,
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Empty.name]: Empty,
    [Cell.name]: Cell,
  },
  data() {
    return {
      page: 0,
      loading: false,
      finished: false,
      refreshing: false,
      screenHeight: 0,
      datas: [],
    };
  },
  mounted() {
    this.screenHeight = document.documentElement.clientHeight - 98;
  },
  methods: {
    formatTime(item) {
      return formatShortTime(item);
    },
    convertPayStatus(val) {
      switch (val) {
        case 1:
          return "待审核";
        case 5:
          return "审核不通过";
        case 6:
          return "审核通过";
        default:
          return "";
      }
    },
    topupDetail(id) {
      this.$router.push({ name: "topupDetail", query: { id: id } });
    },
    onRefresh() {
      this.page = 1;
      this.topupList();
    },
    onLoad() {
      this.page++;
      this.topupList();
    },
    async topupList() {
      const payload = { currentPage: this.page, pageSize: 10 };
      const res = await topupList(payload);
      if (res.success) {
        const { data } = res;
        if (this.page === 1) this.datas = [];
        this.datas = this.datas.concat(data);
        this.finished = data.length < 10;
      }
      this.refreshing = false;
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.van-cell::after {
  border-bottom: 0px;
}

.van-cell {
  line-height: normal;
  margin-top: 0px !important;
  padding: 0px 15px;
  align-items: center;
}

.item-record {
  padding: 10px 0px;
  background-color: white;
}

.van-hairline--bottom::after {
  border-color: #ebedf0;
}
</style>